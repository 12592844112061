import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import * as AOS from 'aos';
import { isPlatformBrowser } from '@angular/common';
import { AnalyticsService } from './services/analytics.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Platfio';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    public updates: SwUpdate,
  ) {
      this.checkForUpdates()
  }
  checkForUpdates() {
    if (!isPlatformBrowser(this.platformId)) return;
    const window = this.document.defaultView;
    if (window?.location.hostname !== 'localhost') {
      // Do not execute the method
      if ('serviceWorker' in navigator) {
        if(navigator) {
          navigator.serviceWorker.getRegistrations().then(registrations => {
            if (registrations.length) {
              this.updates.versionUpdates.subscribe((event) => {
                if (event.type === "VERSION_READY") {
                  document.location.reload();
                }
              })
            } else {
              console.log('No service worker is registered.');
            }
          });
        }
      }
      return;
    }
  }
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      AOS.init();
    }
  }
}
